<template>
  <div class="nav">
    <div class="left">{{ $route.meta.title }}</div>
    <div class="right">
      <div v-if="cliGoCreate" class=" setting center click" @click="$go(cliGoCreate)">
        <img style="width:22px;height:22px" :src="require('@/assets/template/first/personal/create.png')">
        创建
      </div>
      <div class="setting center click" @click="$go('/personal/setting')">
        <img :src="require('@/assets/template/first/personal/setting.png')">
        设置
      </div>
      <div class="logout center click" @click="$showNotice(()=>logout(),'确定退出登录?')">
        <img :src="require('@/assets/template/first/personal/logout.png')">
        退出系统
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
  },
  data() {
    return {
      createInfo: {
        '群众随手拍': '/Readily',
        '在线投诉': '/ConsultingList?type=在线投诉',
        '留言建议': '/AddProposal',
        '在线咨询': '/ConsultingList?type=在线咨询',
        '需求对接': '/DemandCreate',
        '对话书记': '/ConsultingList?type=对话书记',
        '困难帮扶': '/CreateDiff',
        '监督举报': '/ConsultingList?type=监督举报',
        '信息监管': '/ConsultingList?type=信息监管'

      }
    }
  },
  computed: {
    cliGoCreate() {
      return this.createInfo[this.$route.meta.title]
    }
  },

  mounted() {

  },

  methods: {
    logout() {
      this.$store.commit('app/LOGIN_OUT')
      this.$go('/')
    }

  }
}
</script>

<style lang="scss" scoped>
  .nav{
    height: 50px;
    border-bottom: 1px solid #E0E0E0;
    display: flex;
    justify-content: space-between;
    .left{
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      height: 100%;
      border-bottom: 4px solid var(--theme);
    }
    .right{
      display: flex;
      .setting,.logout{
        width: 100px;
        height: 36px;
        border-radius: 4px;

        font-family: Microsoft YaHei;
        font-weight: 400;
        overflow: hidden;
        img{
          width: 16px;
          height: 16px;
          margin-right: 3px;
        }
      }
      .setting{
        margin-right: 20px;
        position: relative;
        color: var(--theme);
        font-size: 16px;
        &::after{
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: var(--theme);
          z-index: -1;
          opacity: .1;
        }
      }
      .logout{
        background: #eeeeee;
        color: #333333;
        font-size: 14px;
      }
    }
  }
</style>
